import React from "react"
import Image from "../../Image/Image"
import Slider from "react-rangeslider"
import { Box, Button, Text } from "@chakra-ui/core"
import { Link } from "gatsby"
import "react-rangeslider/lib/index.css"
import "./Header.scss"

const Header = props => {
  return (
    <Box position="relative" textAlign="center">
      <Link to="/">
        <Image
          class="logo-icon"
          filename="yugioh-logo.png"
          alt="yugioh logo konami"
        />
      </Link>
      {!props.trapCard && props.spellCard && (
        <Link to="/trap">
          <Button
            backgroundColor="#8E2B7B"
            color="white"
            size="md"
            mt="3em"
            mr="2em"
            transition="transform ease 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          >
            Trap
          </Button>
        </Link>
      )}

      {!props.trapCard && !props.spellCard && (
        <Link to="/trap">
          <Button
            backgroundColor="#8E2B7B"
            color="white"
            size="md"
            ml="2em"
            mt="3em"
            mr="2em"
            transition="transform ease 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          >
            Trap
          </Button>
        </Link>
      )}

      {!props.spellCard && (
        <Link to="/spell">
          <Button
            backgroundColor="#229986"
            color="white"
            size="md"
            mt="3em"
            mr="2em"
            transition="transform ease 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          >
            Magic
          </Button>
        </Link>
      )}

      {!props.monster && (
        <Link to="/">
          <Button
            backgroundColor="#897947"
            color="white"
            size="md"
            mt="3em"
            transition="transform ease 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          >
            Monster
          </Button>
        </Link>
      )}

      {props.monster && (
        <Box
          fontSize="lg"
          paddingX={[20, 20, 0, 0]}
          w={["100%", "100%", "250px", "250px"]}
          m="0 auto"
          mt={10}
        >
          <Box>
            <Text color="white" textAlign="center" fontWeight={600}>
              Attack {">"} {props.atk}
            </Text>
            <Slider
              min={0}
              max={5000}
              step={500}
              value={props.atk}
              onChange={value => props.setAtk(value)}
            />
          </Box>
          <Box>
            <Text color="white" textAlign="center" fontWeight={600}>
              Level {props.level}
            </Text>
            <Slider
              min={1}
              max={12}
              step={1}
              value={props.level}
              onChange={value => props.setLevel(value)}
            />
          </Box>
        </Box>
      )}
      <Image filename="puzzle.png" alt="puzzle yugi" class="yugioh-puzzle" />
      {/* <Input
        w="250px"
        m="0 auto"
        mt="2em"
        display="block"
        color="white"
        backgroundColor="rgba(22, 57, 132,0.8)"
        borderColor="rgb(36, 81, 255)"
        outline={0}
        type="text"
        placeholder="Card name..."
        onChange={event => props.setName(event.target.value)}
        _hover={{ backgroundColor: "rgba(22, 57, 132,0.7)" }}
      /> */}
    </Box>
  )
}

export default Header
