import React, { useState, useEffect } from "react"
import axios from "axios"
import Loader from "react-loader-spinner"
import { buildFluidImageData } from "@imgix/gatsby-transform-url"
import Img from "gatsby-image"
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/core"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const Card = props => {
  const [cardArray, setCardArray] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const loadCards = async () => {
    setLoading(true)

    await axios({
      method: "GET",
      url: props.trapCard
        ? "https://db.ygoprodeck.com/api/v7/cardinfo.php?type=trap%20card"
        : props.monster
        ? `https://db.ygoprodeck.com/api/v7/cardinfo.php?&level=${props.level}&atk=gte${props.atk}&sort=name`
        : `https://db.ygoprodeck.com/api/v7/cardinfo.php?type=spell%20card`,
    })
      .then(res => {
        setCardArray(res.data.data)
        setLoading(false)
        setError(false)
      })
      .catch(() => {
        setError(true)
      })
  }

  const displayCard = e => {
    props.setCardId(e)
  }

  useEffect(() => {
    loadCards()
    // eslint-disable-next-line
  }, [props.level, props.atk, props.cardName])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          backgroundColor="rgba(0,0,0,0)"
          border="none"
          shadow="none"
          w={["250px", "250px", "450px", "450px"]}
          mt={["45%", "45%", 100, 100]}
        >
          <ModalHeader />
          <ModalCloseButton
            size={30}
            color="white"
            transition="transform 0.2s ease"
            _hover={{ transform: "scale(1.3)" }}
          />
          <ModalBody>
            {cardArray
              .filter(item => item.id === props.cardId)
              .map((e, i) => (
                <Box
                  key={i}
                  ml="5px"
                  mb="30px"
                  display="inline-block"
                  transition="transform 0.5s ease"
                  cursor="pointer"
                  _hover={{ transform: "scale(1.01)" }}
                  w={["200px", "200px", "400px", "400px"]}
                >
                  <Img
                    fluid={buildFluidImageData(
                      e.card_images[0].image_url,
                      {
                        ar: 0.69,
                        width: "100%",
                        auto: ["format", "compress"],
                      },
                      { sizes: "calc(100vw - 150px)" }
                    )}
                  />
                </Box>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box position="relative" mt="30px" textAlign="center">
        {!error ? (
          loading ? (
            <Box m="0 auto" w="100px">
              <Loader type="Puff" color="#00BFFF" height={80} width={80} />
            </Box>
          ) : props.cardName.length > 0 ? (
            cardArray
              .filter(
                item =>
                  item.name.includes(props.cardName) ||
                  item.desc.includes(props.cardName)
              )
              .map((e, i) => (
                <Box
                  w="150px"
                  ml="5px"
                  display="inline-block"
                  transition="transform 0.5s ease"
                  cursor="pointer"
                  _hover={{ transform: "scale(1.05)" }}
                  onClick={() => {
                    displayCard(e.id)
                    onOpen()
                  }}
                  key={i}
                >
                  <Img
                    onClick={e => displayCard(e)}
                    fluid={buildFluidImageData(
                      e.card_images[0].image_url,
                      {
                        ar: 0.69,
                        width: "100%",
                        auto: ["format", "compress"],
                      },
                      { sizes: "calc(100vw - 150px)" }
                    )}
                  />
                </Box>
              ))
          ) : (
            cardArray.map((e, i) => (
              <Box
                w="150px"
                ml="5px"
                display="inline-block"
                transition="transform 0.5s ease"
                cursor="pointer"
                _hover={{ transform: "scale(1.05)" }}
                onClick={() => {
                  displayCard(e.id)
                  onOpen()
                }}
                key={i}
              >
                <Img
                  fluid={buildFluidImageData(
                    e.card_images[0].image_url,
                    {
                      ar: 0.69,
                      width: "100%",
                      auto: ["format", "compress"],
                    },
                    { sizes: "calc(100vw - 150px)" }
                  )}
                />
              </Box>
            ))
          )
        ) : (
          <Text color="white" fontWeight={600} fontSize="xl">
            No card matches these criteria.
          </Text>
        )}
      </Box>
    </>
  )
}

export default Card
