import { Box, ChakraProvider } from "@chakra-ui/core"
import React from "react"
import { Helmet } from "react-helmet"

const Layout = props => {
  return (
    <Box py={30}>
      <Helmet>
        <html lang="fr" />
        <title>Yu-Gi-Oh App | by @0xpierre.dev</title>
        <meta charset="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="twitter:site" content="Yu-Gi-Oh App | by @0xpierre.dev" />
        <meta name="twitter:creator" content="@0xpierre.dev" />
        <meta
          name="twitter:description"
          content="Yu-Gi-Oh App | by @0xpierre.dev"
        />
        <meta name="robots" content="index, follow" />
        <meta name="twitter:title" content="Yu-Gi-Oh App | by @0xpierre.dev" />
        <meta
          name="og:title"
          property="og:title"
          content="Yu-Gi-Oh App | by @0xpierre.dev"
        />
        <meta name="og:url" content="https://pokeyugi.netlify.app/" />
        <meta name="og:image" content="src/images/favicon.png" />
        <meta name="og:image:type" content="image/png" />
        <meta name="og:description" content="Yu-Gi-Oh App | by @0xpierre.dev" />
        <meta name="og:site_name" content="Yu-Gi-Oh App | by @0xpierre.dev" />
        <meta name="description" content="Yu-Gi-Oh App | by @0xpierre.dev" />
        <meta name="author" content="@0xpierre.dev" />
        <meta name="copyright" content="0xpierre.dev" />
      </Helmet>
      <ChakraProvider>{props.children}</ChakraProvider>
    </Box>
  )
}

export default Layout
